.ism-nav__tab {
  display: inline-block;
  margin: 0.6rem 0;
  padding: 0.3rem 1.8rem;
  border-radius: 40px;
  color: white;
  font-family: "TV 2 Display Medium", "Helvetica", "Arial", sans-serif;
  font-size: 1.4rem;
  line-height: 1;
  text-decoration: none;
  cursor: pointer;
  /* display: flex;
  align-items: center; */
}

.ism-nav__tab.active {
  margin: 0;
  padding: 0.9rem 1.8rem;
  border-radius: 6px;
  background-color: #b2aeff;
  color: #3a2f5c;
  font-weight: 700;
}
.ism-nav__tab.active:hover {
  background-color: #a09bff;
}
