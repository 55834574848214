/* TV 2 Display */

@font-face {
  font-family: "TV 2 Display Medium";
  src: url("./fonts/TV2Display-Medium.woff") format("woff"),
    url("./fonts/TV2Display-Medium.woff2") format("woff2");
}
@font-face {
  font-family: "TV 2 Display Semi Bold";
  src: url("./fonts/TV2Display-SemiBold.woff") format("woff"),
    url("./fonts/TV2Display-SemiBold.woff2") format("woff2");
}

/* TV 2 Text  */

@font-face {
  font-family: "TV 2 Text Light";
  src: url("./fonts/TV2Text-Light.woff") format("woff"),
    url("./fonts/TV2Text-Light.woff2") format("woff2");
}
@font-face {
  font-family: "TV 2 Text Semi Bold";
  src: url("./fonts/TV2Text-SemiBold.woff") format("woff"),
    url("./fonts/TV2Text-SemiBold.woff2") format("woff2");
}
@font-face {
  font-family: "TV 2 Text Bold";
  src: url("./fonts/TV2Text-Bold.woff") format("woff"),
    url("./fonts/TV2Text-Bold.woff2") format("woff2");
}
